import moment from "moment";

export const formatOrderDate = (dateString) => {
  return moment(dateString).format("h:mm A").toString();
};

export const nowIsLater = (dateString) => {
  if (!dateString) return true;
  return moment(dateString).isBefore(moment(new Date().toISOString()));
};

export const formatTime = (timeString) => {
  if (!timeString) {
    return ""; // Return empty string if input is null, undefined, or empty
  }
  const formattedTime = moment(timeString, "HH:mm:ss"); // Ensure the input format
  if (!formattedTime.isValid()) {
    return "Invalid time"; // Provide feedback if time is invalid
  }
  return formattedTime.format("h:mm A");
};
