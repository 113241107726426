import axios from "axios";
import { BASE_URL } from "../constants/ApiEndpoints";

class APIController {
  constructor() {
    const instance = axios.create({
      baseURL: BASE_URL,
      headers: {
        "Content-Type": "application/json",
        // "User-Agent": "APPETITE-TABLET-APP-V1.0-BUILD3654",
      },
    });

    // Set the AUTH token for any request
    instance.interceptors.request.use(function (config) {
      const token = localStorage.getItem("accessToken"); // Adjust how you handle token storage
      config.headers.authorization = token ?? "";
      return config;
    });

    this.instance = instance;
  }

  get(path) {
    return this.instance.get(path);
  }

  post(path, payload) {
    return this.instance.post(path, payload);
  }

  patch(path, payload) {
    return this.instance.patch(path, payload);
  }

  put(path, payload) {
    return this.instance.put(path, payload);
  }

  delete(path) {
    return this.instance.delete(path);
  }
}

export default new APIController(); // Export an instance of the API class
