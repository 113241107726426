import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Splash from "./pages/Splash/Splash";
import Login from "./pages/Login/Login";
import RestaurantPanel from "./pages/Dashboard/Dashboard";
import BaseAppModal from "./common/modals/BaseAppModal";
import OrderDetails from "./pages/OrderDetails/OrderDetails";
import { Toaster } from "react-hot-toast";
import EventBus from "./helpers/EventBus";
import TradingHours from "./pages/Settings/TradingHours/TradingHours";
import AddTradingHours from "./pages/Settings/TradingHours/AddTradingHours";
import AdditionalInterfaces from "./pages/Settings/AdditionalInterfaces/AdditionalInterfaces";
import AddAdditionalInterface from "./pages/Settings/AdditionalInterfaces/AddAdditionalInterface";

function App() {
  useEffect(() => {
    attachListeners();
  }, []);

  const attachListeners = () => {
    window.addEventListener("message", function (event) {
      if (event.data && event.data.type) {
        console.log("Event Data: ", event);

        // Update Push Token Event
        if (event.data.type == "pushToken") {
          EventBus.emit("updatePush", {
            token: event.data.token,
          });
        }

        // New Order Event
        if (event.data.type == "newOrder") {
          console.log("Change to New Order Page: ", event.data.orderId);
          window.location.href = `/order/${event.data.orderId ?? ""}`;
        }
      }
    });
  };

  return (
    <>
      <div>
        <Toaster position={"bottom-center"} />
      </div>
      <Routes>
        {/* Splash */}
        <Route path="" element={<Splash />} />

        {/* Login */}
        <Route path="/login" element={<Login />} />

        {/* Dashboard */}
        <Route path="/restaurant" element={<RestaurantPanel />} />

        {/* Order Details */}
        <Route path="/order/:id" element={<OrderDetails />} />

        {/* Settings */}

        {/* Trading Hours */}
        <Route path="/settings/hours" element={<TradingHours />} />
        <Route path="/settings/hours/add" element={<AddTradingHours />} />

        {/* Additional Interfaces */}
        <Route path="/settings/interfaces" element={<AdditionalInterfaces />} />
        <Route
          path="/settings/interfaces/add"
          element={<AddAdditionalInterface />}
        />
      </Routes>
    </>
  );
}

export default App;
