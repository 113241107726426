import React, { useEffect, useState } from "react";
import BottomNavigationBar from "../../common/navigation/BottomNavigationBar";
import Orders from "../PageStates/Orders";
import CommonHeader from "../../common/CommonHeader";
import Profile from "../PageStates/Profile";
import { changeStatusBarColor } from "../../data/device/DeviceActions";
import EventBus from "../../helpers/EventBus";
import APIController from "../../data/APIController";
import { UPDATE_PUSH_TOKEN } from "../../constants/ApiEndpoints";

const RestaurantPanel = () => {
  //   const restaurantService = new RestaurantAPIService();
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    changeStatusBarColor({ type: "dark-content", color: "#ffffff" });
    EventBus.on("updatePush", updatePushToken);

    setTimeout(() => {
      callPushTokenUpdate();
    }, 2500);

    return () => {
      EventBus.removeListener("updatePush", updatePushToken);
    };
  }, []);

  const callPushTokenUpdate = async () => {
    const message = JSON.stringify({
      type: "updatePushToken",
    });

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(message);
    }
  };

  const updatePushToken = (data) => {
    console.log("PUSHTOKEN EVENT BUS DATA: ", data);
    APIController.post(UPDATE_PUSH_TOKEN, {
      pushToken: data.token,
    })
      .then((response) => {
        console.log("Push Token Response: ", response);
      })
      .catch((error) => {
        console.log("Error Updating Push Token: ", error);
      });
  };

  return (
    <>
      <div className="mt-10 px-6">
        {currentTab !== 3 ? (
          <CommonHeader
            title={
              currentTab == 0 ? "Orders" : currentTab == 1 ? "Bookings" : "Menu"
            }
          />
        ) : null}

        {/* Orders Page */}
        {currentTab == 0 ? <Orders /> : null}

        {/* Profile Page */}
        {currentTab == 3 ? <Profile /> : null}

        {/* Bottom Navigation Bar */}
        <BottomNavigationBar
          currentTab={currentTab}
          changeTab={setCurrentTab}
        />
      </div>
    </>
  );
};

export default RestaurantPanel;
