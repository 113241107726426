import React from "react";
import FilledButton from "../FilledButton";

const PreparingButtonGroup = ({ onPrint, onReady, onActions }) => {
  return (
    <div className="grid grid-cols-1 gap-2">
      <div className="w-full">
        <FilledButton
          text={"Print Order"}
          backgroundColor={"#FA9235"}
          paddingVertical={10}
          onPress={onPrint}
        />
      </div>

      <div className="w-full">
        <FilledButton
          text={"Order Ready"}
          paddingVertical={10}
          backgroundColor={"#228B22"}
          onPress={onReady}
        />
      </div>

      <div className="w-full">
        <FilledButton
          text={"Order Actions"}
          paddingVertical={10}
          backgroundColor={"#4166F5"}
          onPress={onActions}
        />
      </div>
    </div>
  );
};

export default PreparingButtonGroup;
