import React from "react";
import FilledButton from "../FilledButton";

const AwaitingButtonGroup = ({ onAccept, onReject }) => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="w-full">
        <FilledButton
          text={"Accept Order"}
          paddingVertical={10}
          onPress={onAccept}
        />
      </div>

      <div className="w-full">
        <FilledButton
          text={"Decline Order"}
          paddingVertical={10}
          backgroundColor={"#FF4A49"}
          onPress={onReject}
        />
      </div>
    </div>
  );
};

export default AwaitingButtonGroup;
