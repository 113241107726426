import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { QrReader } from "react-qr-reader";
import { FaChevronLeft } from "react-icons/fa";
import FilledButton from "../../../common/FilledButton";
import { changeStatusBarColor } from "../../../data/device/DeviceActions";
import { showToastMessage } from "../../../helpers/ToastHelpers";

const AddAdditionalInterface = () => {
  const navigate = useNavigate();

  useEffect(() => {
    changeStatusBarColor({ type: "light-content", color: "#000000" });
    return () => {
      changeStatusBarColor({ type: "dark-content", color: "#ffffff" });
    };
  }, []);

  const handleScan = (data) => {
    if (data) {
      console.log("Scanned data: ", data);
      const deviceData = JSON.parse(data);
      navigate(-1);
      showToastMessage(
        `Connecting Device`,
        `Your ${deviceData.deviceType} device should change momentarily.`
      );
    }
  };

  const handleNavigateAway = () => {
    navigate("/settings/interfaces"); // Adjust according to your route
  };

  return (
    <div className="fixed inset-0 overflow-hidden">
      <QrReader
        onResult={(result, error) => {
          if (result) handleScan(result.getText());
        }}
        style={{ width: "100vw", height: "100vh" }}
        videoContainerStyle={{ width: "100vw", height: "100vh" }}
        videoStyle={{ width: "100%", height: "100%", objectFit: "cover" }}
      />

      <div className="absolute top-0 bottom-0 left-0 right-0 w-full">
        <div className="flex flex-row fixed top-4 pt-6 pb-4 px-6 w-screen">
          <FaChevronLeft
            size={20}
            color={"#ffffff"}
            onClick={handleNavigateAway}
          />

          <div style={{ flex: 1 }} />
          <p className="text-base text-white font-medium">
            Add Additional Interface
          </p>
          <div style={{ flex: 1 }} />

          <div style={{ width: 20 }} />
        </div>

        <div className="absolute bottom-32 left-0 right-0 w-full px-6">
          <h4 className="text-white text-lg text-center font-medium">
            Scan the QR code present on your additional interface to connect it
            to your Restaurant Profile
          </h4>
        </div>
      </div>
      <div className="absolute bottom-6 left-0 right-0 px-6 w-full">
        <FilledButton text={"Cancel"} onPress={handleNavigateAway} />
      </div>
    </div>
  );
};

export default AddAdditionalInterface;
