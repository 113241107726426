// export const BASE_URL = "http://192.168.0.52:3004/api/";
export const BASE_URL = "https://api.appetitepos.com.au/api/";

// Login Endpoint
export const LOGIN_RESTAURANT = "restaurant/login";

// Restaurant Profile
export const GET_RESTAURANT_PROFILE = "restaurant/profile/get";
export const UPDATE_RESTAURANT_STATUS = "restaurant/store/online";
export const UPDATE_PUSH_TOKEN = "restaurant/push/update";

// Restaurant Updates
export const GET_RESTAURANT_NOTICES = "tablet/updates/get";

// Orders
export const GET_ACTIVE_ORDERS = "restaurant/orders/get/";
export const GET_PAST_ORDERS = "restaurant/orders/past/get/";
export const GET_ORDER_DETAILS = "restaurant/orders/details/get/";

// Order Actions
export const ACCEPT_ORDER = "orders/actions/accept";
export const REJECT_ORDER = "orders/actions/reject";
export const ORDER_READY = "orders/actions/ready";
export const ORDER_PICKED_UP = "orders/actions/pickup";
export const DELAY_ORDER = "orders/actions/delay";

// Trading Hours
export const GET_RESTAURANT_HOURS = "restaurant/hours/get/";
export const ADD_TRADING_HOURS = "restaurant/hours/create";
export const DELETE_TRADING_HOURS = "restaurant/hours/delete";

// Additional Interfaces
export const GET_RESTAURANT_INTERFACES = "restaurant/interfaces/get/";
