import { useContext, useEffect } from "react";

import { showAPIError } from "../../helpers/FieldHelpers";
import { GET_RESTAURANT_PROFILE } from "../../constants/ApiEndpoints";
import { RestaurantContext } from "../../data/contexts/RestaurantContext";
import APIController from "../../data/APIController";
import { useNavigate } from "react-router-dom";

const Splash = () => {
  const navigate = useNavigate();
  const { restaurant, setRestaurant } = useContext(RestaurantContext);

  useEffect(() => {
    setTimeout(() => {
      checkUserLoggedIn();
    }, 250);
  }, []);

  const checkUserLoggedIn = () => {
    const token = localStorage.getItem("accessToken");

    if (!token) {
      window.location.href = "/login";
    } else {
      getRestaurantProfile();
    }
  };

  const getRestaurantProfile = () => {
    APIController.get(GET_RESTAURANT_PROFILE)
      .then((response) => {
        console.log("Restaurant Profile Response: ", response);

        if (response.data.success == false) {
          showAPIError(response.data.message);
          localStorage.clear();
          navigate("/login");
          return;
        }

        setRestaurant(response.data.data);
        navigate("/restaurant");
      })
      .catch((error) => {
        console.log("Error finding restaurant profile", error);
      });
  };

  return (
    <>
      <div className="absolute top-0 bottom-0 left-0 right-0 w-full h-full bg-[#55B836] flex justify-center items-center">
        <img
          alt={"Appetite Point of Sale"}
          className="contain w-[220px] h-[220px]"
          src={require("../../assets/images/ic_appetite_logo.webp")}
        />
      </div>
    </>
  );
};

export default Splash;
