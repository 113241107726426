import React, { useContext, useEffect, useState } from "react";
import CommonLoader from "../../../common/CommonLoader";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "../../../data/contexts/RestaurantContext";
import APIController from "../../../data/APIController";
import { GET_RESTAURANT_INTERFACES } from "../../../constants/ApiEndpoints";
import FilledButton from "../../../common/FilledButton";

const AdditionalInterfaces = () => {
  const navigate = useNavigate();
  const { restaurant } = useContext(RestaurantContext);

  const [loading, setLoading] = useState(true);
  const [interfaces, setInterfaces] = useState([]);

  const getInterfaces = () => {
    setLoading(true);
    APIController.get(GET_RESTAURANT_INTERFACES + restaurant?.id ?? "")
      .then((response) => {
        console.log("INTERFACE RESPONSE: ", response.data);
        setInterfaces(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getInterfaces();
  }, []);

  return (
    <>
      {loading ? (
        <div
          className="pt-12"
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CommonLoader width={128} height={128} />
          <p className="font-medium mt-2">Updating Interfaces</p>
        </div>
      ) : (
        <>
          <div className="flex flex-row fixed top-0 pt-6 pb-4 px-6 bg-white w-screen">
            <FaChevronLeft
              size={20}
              onClick={() => {
                navigate("/restaurant");
              }}
            />

            <div style={{ flex: 1 }} />
            <p className="text-base font-medium">Additional Interfaces</p>
            <div style={{ flex: 1 }} />

            <FaChevronLeft size={20} color={"#ffffff"} />
          </div>

          {interfaces.length > 0 ? (
            <></>
          ) : (
            <>
              <div
                className="pt-12"
                style={{
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p className="font-medium mt-2">
                  You have not added any additional interfaces
                </p>

                <div className="px-6 w-full mt-2">
                  <FilledButton
                    text={"Add Additional Interface"}
                    onPress={() => navigate("/settings/interfaces/add")}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AdditionalInterfaces;
